<template>
	<div class="s-card-container">
		<div class="scc-search-btn">
			<a-input-search
				placeholder="请输入文章标题"
				enter-button="搜索"
				v-model="searchValue"
				@search="onSearch"
			/>
		</div>
		<a-tabs type="card" @change="(ac) => handleTabChange(ac, true)">
			<a-tab-pane
				v-for="item of classList"
				:key="item._id"
				:tab="item.name"
			>
				<div class="s-sub-tab-title">
					<span class="category-text">类目：</span>
					<a-checkable-tag
						v-for="itemChil of item.children"
						:key="itemChil._id"
						:checked="checkedList.indexOf(itemChil._id) > -1"
						@change="handleTabChange(itemChil._id, false)"
					>
						{{ itemChil.name }}
					</a-checkable-tag>
				</div>
				<div class="article-list">
					<a-row :gutter="16">
						<a-col
							class="gutter-row"
							:span="4"
							v-for="(fileItem, fileIndex) of fileList"
							:key="fileIndex"
						>
							<a-card
								:hoverable="true"
								@click="goPreviewHandle(fileItem)"
								style="margin-bottom: 8px;"
							>
								<img
									slot="cover"
									alt="example"
									class="self-card-cover"
									:src="fileItem.cover"
								/>
								<a-card-meta>
									<template slot="title">
										<img
											src="https://huhuiyun.oss-cn-shanghai.aliyuncs.com/static/ico_word.svg"
										/>
										<a-tooltip
											placement="top"
											:title="fileItem.title"
										>
											{{ fileItem.title }}
										</a-tooltip>
									</template>
									<template slot="description">
										使用量：{{ fileItem.downloads }}
									</template>
								</a-card-meta>
							</a-card>
						</a-col>
					</a-row>
				</div>
				<div class="article-pagination">
					<a-pagination
						show-size-changer
						:default-current="1"
						:current="pagination.current"
						:pageSize="pagination.pageSize"
						:total="pagination.total"
						:pageSizeOptions="pagination.pageSizeOptions"
						@change="paginationChange"
						@showSizeChange="onShowSizeChange"
					/>
				</div>
			</a-tab-pane>
		</a-tabs>
	</div>
</template>
<script>
import { getModelTextClass, getModelText } from '@/api/thinkTank/modelText'
export default {
	data() {
		const fileUrl =
			'https://huhuiyun.oss-cn-shanghai.aliyuncs.com/static/1615899553292.pdf'
		return {
			searchValue: null,
			checkedList: [],
			classList: [],
			fileList: [],
			pagination: {
				total: 0,
				pageSize: 10,
				current: 1,
				pageSizeOptions: ['10', '20', '50', '100'],
			},
			selectedClassIds: [],
		}
	},
	created() {
		getModelTextClass().then((res) => {
			this.classList = res.list
			this.handleTabChange(res.list[0]._id, true)
			this.getTableData()
		})
	},
	methods: {
		getTableData() {
			const { pageSize, current } = this.pagination
			const params = {
				pageNum: current,
				pageSize: pageSize,
				title: this.searchValue,
				modelTextClassIds: this.selectedClassIds,
			}
			getModelText(params).then((res) => {
				this.pagination.current = res.pageNum
				this.pagination.pageSize = res.pageSize
				this.pagination.total = res.total
				this.fileList = res.list
			})
		},
		onSearch(value) {
			this.getTableData()
		},
		goPreviewHandle(record) {
			this.$router.push(`/thinkTank/modelText/preview/${record._id}`)
		},
		handleTabChange(_id, isTab) {
			this.searchValue = null
			this.selectedClassIds = []
			if (isTab) {
				this.checkedList = []
				let finded = this.classList.find((item) => item._id === _id)
				if (finded && finded.children && finded.children.length > 0) {
					let classIds = []
					finded.children.forEach((item) => {
						classIds.push(item._id)
					})
					this.selectedClassIds = classIds
				}
			} else {
				if (this.checkedList.indexOf(_id) > -1) {
					this.checkedList.splice(this.checkedList.indexOf(_id), 1)
				} else {
					this.checkedList.push(_id)
				}
				this.selectedClassIds = this.checkedList
			}
			this.getTableData()
		},
		onShowSizeChange(current, pageSize) {
			console.log(current, pageSize)
			this.pagination.pageSize = pageSize
			this.getTableData()
		},
		paginationChange(page, pageSize) {
			this.pagination.pageSize = pageSize
			this.pagination.current = page
			this.getTableData()
		},
	},
}
</script>
<style lang="less" scope="this api replaced by slot-scope in 2.5.0+">
.s-card-container {
	.ant-tabs-tabpane {
		background: #fff;
		padding: 16px;
	}
	.scc-search-btn {
		width: 40%;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 16px;
	}
	.s-sub-tab-title {
		padding-bottom: 12px;
		border-bottom: 1px solid #eeeeee;
		.category-text {
			color: #333333;
		}
		.ant-tag {
			cursor: pointer;
		}
	}
	.article-list {
		margin-top: 24px;
		.self-card-cover {
			width: 178px;
			height: 230px;
				img {
					height: 100%;
					width: 100%;
				}
		}
		.ant-card-body {
			padding: 8px;
			.ant-card-meta-title {
				img {
					width: 24px;
					height: 24px;
					margin-right: 8px;
				}
				font-size: 14px;
			}
			.ant-card-meta-description {
				text-align: right;
			}
		}
	}
	.article-pagination {
		margin-top: 26px;
		width: 50%;
		margin-left: auto;
		margin-right: auto;
		.ant-pagination {
			text-align: center;
		}
	}
}
</style>
