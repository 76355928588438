import request from "@/api/request";

// 获取示范文本类目
export function getModelTextClass() {
  return request({
    url: "/api/thinkTank/modelText/class",
    method: "get",
  });
}


// 获取示范文本 文章列表
export function getModelText(query) {
  return request({
    url: "/api/thinkTank/modelText",
    method: "get",
    params: query,

  });
}


// 获取示范文本 热门下载 文章列表
export function getModelTextHotList(query) {
  return request({
    url: "/api/thinkTank/modelText/hot",
    method: "get",
    params: query,

  });
}

export function getModelTextById(id) {
  return request({
    url: `/api/thinkTank/modelText/detail/${id}`,
    method: "get",

  });
}

export function updateModelTextDownloads(id) {
  return request({
    url: `/api/thinkTank/modelText/downloads/${id}`,
    method: "put",

  });
}


